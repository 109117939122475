/***********************************************
         Invenio App RDM Message Overrides
***********************************************/


.ui.flashed.message,
.ui.form .flashed.message {
  &.manage {
    padding: 1em 0;
  }
}

#record-tab-panel > .banners .ui.flashed.message.manage {
  padding: 1rem;
}

.ui.message.file-box-message {
  box-shadow: none !important;
  border-bottom-right-radius: @defaultBorderRadius !important;
  border-bottom-left-radius: @defaultBorderRadius !important;
  margin: 0 !important;

  h4 {
    font-weight: @bold !important;
  }
}

.ui.message .ui.checkbox label {
  color: currentColor;
}

.ui.message .ui.checkbox {
  margin-top: @defaultMargin / 2.5;
}

.ui.message.community-branding-error {
  max-width: 25em;
}

/***********************************************
    Invenio App RDM Dropdown Overrides
***********************************************/

/* Dropdown Carets */
@font-face {
  font-family: 'Dropdown';
  src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAAVgAA8AAAAACFAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAchGgaq0dERUYAAAF0AAAAHAAAAB4AJwAPT1MvMgAAAZAAAABDAAAAVnW4TJdjbWFwAAAB1AAAAEsAAAFS8CcaqmN2dCAAAAIgAAAABAAAAAQAEQFEZ2FzcAAAAiQAAAAIAAAACP//AANnbHlmAAACLAAAAQoAAAGkrRHP9WhlYWQAAAM4AAAAMAAAADYPK8YyaGhlYQAAA2gAAAAdAAAAJANCAb1obXR4AAADiAAAACIAAAAiCBkAOGxvY2EAAAOsAAAAFAAAABQBnAIybWF4cAAAA8AAAAAfAAAAIAEVAF5uYW1lAAAD4AAAATAAAAKMFGlj5HBvc3QAAAUQAAAARgAAAHJoedjqd2ViZgAABVgAAAAGAAAABrO7W5UAAAABAAAAANXulPUAAAAA1r4hgAAAAADXu2Q1eNpjYGRgYOABYjEgZmJgBEIOIGYB8xgAA/YAN3jaY2BktGOcwMDKwMI4jTGNgYHBHUp/ZZBkaGFgYGJgZWbACgLSXFMYHFT/fLjFeOD/AQY9xjMMbkBhRpAcAN48DQYAeNpjYGBgZoBgGQZGBhDwAfIYwXwWBgMgzQGETAwMqn8+8H649f8/lHX9//9b7Pzf+fWgusCAkY0BzmUE6gHpQwGMDMMeAACbxg7SAAARAUQAAAAB//8AAnjadZBPSsNAGMXfS+yMqYgOhpSuSlKadmUhiVEhEMQzFF22m17BbbvzCh5BXCUn6EG8gjeQ4DepwYo4i+/ffL95j4EDA+CFC7jQuKyIeVHrI3wkleq9F7XrSInKteOeHdda8bOoaeepSc00NWPz/LRec9G8GabyGtEdF7h19z033GAMTK7zbM42xNEZpzYof0RtQ5CUHAQJ73OtVyutc+3b7Ou//b8XNlsPx3jgjUifABdhEohKJJL5iM5p39uqc7X1+sRQSqmGrUVhlsJ4lpmEUVwyT8SUYtg0P9DyNzPADDs+tjrGV6KRCRfsui3eHcL4/p8ZXvfMlcnEU+CLv7hDykOP+AKTPTxbAAB42mNgZGBgAGKuf5KP4vltvjLIMzGAwLV9ig0g+vruFFMQzdjACOJzMIClARh0CTJ42mNgZGBgPPD/AJD8wgAEjA0MjAyogAMAbOQEAQAAAAC7ABEAAAAAAKoAAAH0AAABgAAAAUAACAFAAAgAwAAXAAAAAAAAACoAKgAqADIAbACGAKAAugDSeNpjYGRgYOBkUGFgYgABEMkFhAwM/xn0QAIADdUBdAB42qWQvUoDQRSFv3GjaISUQaymSmGxJoGAsRC0iPYLsU50Y6IxrvlRtPCJJKUPIBb+PIHv4EN4djKuKAqCDHfmu+feOdwZoMCUAJNbAlYUMzaUlM14jjxbngOq7HnOia89z1Pk1vMCa9x7ztPkzfMyJbPj+ZGi6Xp+omxuPD+zaD7meaFg7mb8GrBqHmhwxoAxlm0uiRkpP9X5m26pKRoMxTGR1D49Dv/Yb/91o6l8qL6eu5n2hZQzn68utR9m3FU2cB4t9cdSLG2utI+44Eh/P9bqKO+oJ/WxmXssj77YkrjasZQD6SFddythk3Wtzrf+UF2p076Udla1VNzsERP3kkjVRKel7mp1udXYcHtZSlV7RfmJe1GiFWveluaeKD5/MuJcSk8Tpm/vvwPIbmJleNpjYGKAAFYG7ICTgYGRiZGZkYWRlZGNkZ2Rg5GTLT2nsiDDEEIZsZfmZRqZujmDaDcDAxcI7WIOpS2gtCWUdgQAZkcSmQAAAAFblbO6AAA=) format('woff')
;
  font-weight: normal;
  font-style: normal;
}

.ui.dropdown > .dropdown.icon {
  font-family: 'Dropdown';
  line-height: 1;
  height: 1em;
  width: 1.23em;
  backface-visibility: hidden;
  font-weight: normal;
  font-style: normal;
  text-align: center;
}

.ui.dropdown > .dropdown.icon {
  width: auto;
}

.ui.dropdown > .dropdown.icon:before {
  content: '\f0d7';
}

/* Sub Menu */
.ui.dropdown .menu .item .dropdown.icon:before {
  content: '\f0da' /*rtl:'\f0d9'*/;
}

.ui.dropdown .item .left.dropdown.icon:before,
.ui.dropdown .left.menu .item .dropdown.icon:before {
  content: "\f0d9" /*rtl:"\f0da"*/;
}

/* Vertical Menu Dropdown */
.ui.vertical.menu .dropdown.item > .dropdown.icon:before {
  content: "\f0da" /*rtl:"\f0d9"*/;
}

.ui.dropdown > .clear.icon:before {
  content: "\f00d";
}

.ui.dropdown.share-link-dropdown {
  line-height: normal;
}


/* Icons for Reference (Subsetted in 2.4.0)
  .dropdown.down:before { content: "\f0d7"; }
  .dropdown.up:before { content: "\f0d8"; }
  .dropdown.left:before { content: "\f0d9"; }
  .dropdown.right:before { content: "\f0da"; }
  .dropdown.close:before { content: "\f00d"; }
*/

.ui.dropdown.citation-dropdown {
  min-width: 10em;
}

.ui.dropdown {
  &.fluid-responsive {
    @media all and (max-width: @largestTabletScreen) {
      width: 100%;
      display: block;

      & > .dropdown.icon {
        float: right;
      }
    }
  }

  a.item:hover {
    text-decoration: none;
  }
}

@minWidthSmallerScreens: calc(100% + 5rem);
.members-dropdown-container, .access-dropdown-container {
  .ui.dropdown {
    border: @selectionBorder;
    padding: @selectionVerticalPadding @selectionHorizontalPadding;
    border-radius: @selectionBorderRadius;

    .menu {
      overflow-x: auto;
      overflow-y: hidden;
    }

    .active.selected.item {
      font-weight: normal;

    }
  }
  .ui.dropdown.floating {
    .dropdown.icon {
      padding-top: 0.25em;
    }
  }

.action-status-container {
  width: 1em;
  padding: 0 @selectionHorizontalPadding;
  border-radius: @selectionBorderRadius;
}

  @media screen and (max-width: @largestTabletScreen) {
    .ui.dropdown {
      .menu {
        min-width: @minWidthSmallerScreens;
      }

      * {
        white-space: normal;
      }
    }
  }

}

.mobile-table-cell .members-dropdown-container {
  .ui.dropdown > .menu {
    @media all and (max-width: @largestMobileScreen) {
      min-width: @minWidthSmallerScreens !important;
    }
  }

}

.mobile-table-cell .ui.dropdown {
  @media all and (max-width: @largestMobileScreen) {
    min-width: 7em !important;

    & > .menu {
      min-width: 14em !important;
      border-top-width: 1px !important;
      border-radius: @defaultBorderRadius 0 @defaultBorderRadius @defaultBorderRadius;
    }
  }
}

.manage-menu-dropdown {
  .dropdown.icon {
    margin-left: 0 !important;
    color: @grey;
  }
}

.ui.inline.selection.dropdown .dropdown.icon{
  // bug in SUI
  margin: @selectionIconMargin !important;
}

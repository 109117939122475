/***********************************************
         Invenio Theme Form Overrides
***********************************************/

.ui.form .field small {
  color: @grey;
  margin-top: 0.4em !important;
}


.invenio-administration {
  .ui[class*="equal width"].form:not(.unstackable) .fields .field,
  .ui.form:not(.unstackable) [class*="equal width"].fields:not(.unstackable) .field,
  .ui.form:not(.unstackable) .two.fields:not(.unstackable) .fields,
  .ui.form:not(.unstackable) .two.fields:not(.unstackable) .field,
  .ui.form:not(.unstackable) .three.fields:not(.unstackable) .fields,
  .ui.form:not(.unstackable) .three.fields:not(.unstackable) .field,
  .ui.form:not(.unstackable) .four.fields:not(.unstackable) .fields,
  .ui.form:not(.unstackable) .four.fields:not(.unstackable) .field,
  .ui.form:not(.unstackable) .five.fields:not(.unstackable) .fields,
  .ui.form:not(.unstackable) .five.fields:not(.unstackable) .field,
  .ui.form:not(.unstackable) .six.fields:not(.unstackable) .fields,
  .ui.form:not(.unstackable) .six.fields:not(.unstackable) .field,
  .ui.form:not(.unstackable) .seven.fields:not(.unstackable) .fields,
  .ui.form:not(.unstackable) .seven.fields:not(.unstackable) .field,
  .ui.form:not(.unstackable) .eight.fields:not(.unstackable) .fields,
  .ui.form:not(.unstackable) .eight.fields:not(.unstackable) .field,
  .ui.form:not(.unstackable) .nine.fields:not(.unstackable) .fields,
  .ui.form:not(.unstackable) .nine.fields:not(.unstackable) .field,
  .ui.form:not(.unstackable) .ten.fields:not(.unstackable) .fields,
  .ui.form:not(.unstackable) .ten.fields:not(.unstackable) .field {
    width: @oneColumn !important;
    margin: 0em 0em @rowDistance;
  }

  .ui[class*="equal width"].form .fields .field,
  .ui.form [class*="equal width"].fields .field {
    width: 100%;
    flex: 1 1 auto;
  }
}
